<template>
  <v-scale-transition>
    <kits-panel
      :kits-items="dealershipKits"
      :breadcrumbs="breadcrumbs"
    >
      <h3 class="headline grey--text text-xs-center hidden-sm-and-down">
        E-recharge
      </h3>
    </kits-panel>
  </v-scale-transition>
</template>

<script>
// import security from '@/security'
const formTitle = 'E-recharge'

export default {
  components: {
    KitsPanel: () => import(/* webpackChunkName: "kitsPanel" */ '@/components/KitsPanel'),
  },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Dealer Kits', disabled: true, to: '/dealer_kits',
        },
        {
          text: formTitle, disabled: true,
        },
      ],
    }
  },
  computed: {
    dealershipKits: function () {
      const menus = []
      /*
        const menus = [
          {
            icon: 'system_update',
            path: '/prepaid_topup',
            title: 'Topup Prepaid number',
            desc: 'Use e-recharge to do topup for subscriber.',
          },
        ]
        security.me.blackDealer() && menus.push(
          {
            icon: 'payment',
            path: '/black_pay_bill',
            title: 'Pay Black bill',
            desc: 'Use e-recharge to pay Black bill.',
          },
        )
        */
      menus.push(
        {
          icon: 'swap_horiz',
          path: '/ecash_transfer_demoted',
          title: 'Transfer e-recharge',
          desc: 'Transfer e-recharge to other dealer.',
        },
        {
          icon: 'mdi-view-list',
          path: '/ecash_record_demoted',
          title: 'E-recharge Record',
          desc: 'View e-recharge transaction records.',
        },
        /*
          {
            icon: 'mdi-gift-outline',
            path: '/erecharge_donate_tkkr',
            title: 'Donate to TKKR SAG',
            desc: 'Support our own motorsports team!',
            image: 'img/tkkr_sag.jpg'
          }
          {
            icon: 'mdi-cart',
            path: '/ecash_purchase',
            title: 'Purchase E-recharge',
            desc: 'Purchase e-recharge online from HQ'
          }
          */
      )

      return menus
    },
  },
}
</script>
